/**
 * Main JS
 */
(function($) {

  document.fonts.ready.then(function () {
    document.getElementById('text-logo').classList.remove('visibility-hidden');
    document.getElementById('img-logo').classList.add('hidden');
    jQuery('ul#menu-main-menu li a').addClass('ff-loaded');
    setTimeout(function () {
      document.getElementById('text-logo').classList.remove('visibility-hidden');
      document.getElementById('img-logo').classList.add('hidden');
      jQuery('ul#menu-main-menu li a').addClass('ff-loaded');
    }, 500);
  });

  /**
   * Add aria labels to buttons in carousels
   */
  function set_carousel_aria_labels () {

    const carousels = $('.owl-carousel');
    if (carousels.length) {
      set_custom_owl_nav_aria();
      set_owl_dots_aria();
    }

  }//end set_carousel_aria_labels


  function set_owl_dots_aria () {

    const owl_dots = $('div.theme-owl-dots-container');
    if (owl_dots.length) {
      owl_dots.each(function(){

        let $this = $(this);
        if ($this.find('button').length) {
          let buttons = $this.find('button'),
              counter = 1;
          buttons.each(function(){
            $(this).attr('aria-label', 'Item '+counter);
            counter++;
          })
        }

      });
    }

  }//end set_custom_owl_dots_aria


  function set_custom_owl_nav_aria() {
    if ($('.theme-owl-nav-container').length) {
      $('.theme-owl-nav-container').each(function () {

        let $this = $(this);
        $this.find('button.owl-next').attr('aria-label', 'Next').attr('role', 'button');
        $this.find('button.owl-prev').attr('aria-label', 'Previous').attr('role', 'button');

      });
    }
  }//end set_custom_owl_nav_aria


  //Toggle tabindex for active owl-items buttons for keyboard nav
  function set_carousel_buttons_tabindex (this_carousel) {

    // Retrieve all owl-item elements within this carousel
    let owl_items = this_carousel.$element.find('.owl-item');

    if (owl_items.length) {
      owl_items.each(function(){

        let $this_item = $(this);
        if ($this_item.hasClass('active')) {
          $this_item.find('.col-carousel-button').attr('tabindex', '0');
        } else {
          $this_item.find('.col-carousel-button').attr('tabindex', '-1');
        }

      });
    }


  }//end set_carousel_cols_buttons_tabindex


  /**
   * Align content columns
   */
  function align_columns () {

    let sections = $('section.content-image-columns-section');
    if (sections.length && $(window).width() > 991) {

      sections.each(function(){
        let $this_section = $(this);
        let cols = $this_section.find('div.col-inner');

        let heading_min_h = 0,
            content_min_h = 0;

        cols.each(function(){

          let $this = $(this),
              heading_wrap = $this.find('.heading-wrap'),
              content_wrap = $this.find('.content-wrap');

          heading_wrap.css('height', 'auto');
          content_wrap.css('height', 'auto');

          let heading_h = heading_wrap.height(),
              content_h = content_wrap.height();

          if (parseInt(heading_h) > heading_min_h) {
            heading_min_h = heading_h;
          }

          if (parseInt(content_h) > content_min_h) {
            content_min_h = content_h;
          }

        });//end each

        cols.find('.heading-wrap').height(heading_min_h);
        cols.find('.content-wrap').height(content_min_h);
      });


    } else {//end if sections len
      if ($('section.content-image-columns-section div.col-inner').length) {
        let cols = $('section.content-image-columns-section div.col-inner');
        cols.find('.heading-wrap').css('height', 'auto');
        cols.find('.content-wrap').css('height', 'auto');
      }
    }


  }//end align_columns


  $(function() {


    setTimeout(function () {
      align_columns();
    }, 350);

    $(window).on('resize', function(){
      align_columns();
    });

    //Init lightbox
    if (typeof GLightbox !== 'undefined') {
      let lightbox = GLightbox({
        openEffect: 'fade',
        closeEffect: 'fade',
        slideEffect: 'fade',
        loop: true
      });
    }


    //CPT hero gallery:
    if ($('.hero-carousel').length) {

      $('.hero-carousel').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          margin: 0,
          navText: ['<img loading="lazy" src="/wp-content/themes/KilpinCo/assets/img/icons/chevron-left-white.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/KilpinCo/assets/img/icons/chevron-right-white.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          autoplay: true,
          autoplayHoverPause: true,
          autoplaySpeed: 3200,
          navSpeed: 3200,
          dotsSpeed: 3200,
          loop: true,
          animateOut: 'fadeOut',
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
              0: {
                nav: false,
                dots: true,
              },
              768: {
                nav: false,
                dots: true,
              },
              1024: {
                nav: true
              },
              1200: {
                nav: true
              },
              1366: {
                nav: true
              }
            }
        });

    }//end cpt-gallery len


    //Page scroll
    $("a.page-scroll, a.scroll-to, li.page-scroll.current-menu-item > a").bind("click", function (e) {

      let target = $(this).attr("href");

      if (!target.length || !$(target).length) {
        return false;
      }

      $("html, body").scrollTop(get_section_scroll_to_offset(target));

      e.preventDefault();

    });


    //Gallery carousel
    if ($('.gallery-carousel').length) {

      let carousels = $('.gallery-carousel'),
          max_items = 3;

      carousels.each(function(){

        let $this = $(this),
            items = $this.data('items-count'),
            nav_container = $this.data('nav-container'),
            dots_container = $this.data('dots-container'),
            margin = $this.data('margin'),
            bg_color = (typeof $this.data('bg-color') !== 'undefined') ? $this.data('bg-color') : 'lightblue';

        let arrows_color = 'lightblue';
        switch (bg_color) {

          case 'bg-color-white':
          case 'bg-color-pale_blue':
            arrows_color = 'lightblue';
          break;

          case 'bg-color-blue':
          case 'bg-color-med_blue':
            arrows_color = 'white';
          break;

          case 'bg-color-light_blue':
            arrows_color = 'blue';
          break;

          case 'bg-color-light_pink':
            arrows_color = 'pink';
          break;

        }

        if (items > max_items) {
          items = max_items;
        }

        $this.owlCarousel({
          items: items,
          dots: true,
          nav: true,
          navContainer: nav_container,
          dotsContainer: dots_container,
          margin: margin,
          navText: ['<img loading="lazy" src="/wp-content/themes/KilpinCo/assets/img/icons/chevron-left-white.svg" alt="Arrow left"/>', '<img loading="lazy" src="/wp-content/themes/KilpinCo/assets/img/icons/chevron-right-white.svg" alt="Arrow left"/>'],
          responsiveClass: true,
          onInitialized: function (e) {
            set_carousel_buttons_tabindex(e.relatedTarget);
          },
          onChanged: function(e){
            let this_carousel = e.relatedTarget;
            setTimeout(function () {
              set_carousel_buttons_tabindex(this_carousel);
            }, 100);
          },
          responsive: {
            0: {
              items: 1,
              margin: 0,
              dots: false
            },
            768: {
              items: 2,
              dots: true,
            },
            1024: {
              items: items,
              dots: true,
            },
            1200: {
              items: items,
              dots: true,
            },
            1366: {
              items: items,
              dots: true,
            }
          }
        });

      });

    }//end gallery carousel


    //Set carousel aria labels
    set_carousel_aria_labels();


    //Nav button toggles:
    $('#navbarCollapse').on('show.bs.collapse', function () {
      $('body').addClass('ofh');
    });
    $('#navbarCollapse').on('hide.bs.collapse', function () {
      $('body').removeClass('ofh');
    });

  });//end DOM loaded


})(jQuery); // Fully reference jQuery after this point.
